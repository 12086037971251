@import url('https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi+Fun:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');
.plus{
    font-family: 'Plus Jakarta Sans', sans-serif;
}
.Poppins{
    font-family: 'Poppins', sans-serif;
  }
  .Quicksand{
    font-family: 'Quicksand', sans-serif;
  }
.bga{
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2e2447+0,1f2029+100 */
background: rgb(2, 12, 27); /* Old browsers */
background: -moz-linear-gradient(top,   rgb(23, 23, 24) 0% rgb(28, 29, 36) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,   rgb(23, 23, 24) 0%,rgb(28, 29, 36) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  rgb(16, 16, 17) 0%,rgb(28, 29, 36) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#020c1b', endColorstr='#1f2029',GradientType=0 ); /* IE6-9 */

}

.inter{
  font-family: 'Inter', sans-serif;
}
.roboto{
font-family: 'Roboto', sans-serif;
}
.b{
    -webkit-tap-highlight-color: transparent;
}


.b{
    -webkit-tap-highlight-color: transparent;
}
html {
  scroll-behavior: smooth;
}